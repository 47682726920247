import React, { useContext } from 'react';
import { Button, Table, Tooltip } from '@flixbus/honeycomb-react';
import { Icon, IconInfo } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../../../../shared/system/translate';
import { getUtcOffsetText, parseDate } from '../../../../../shared/util/date';
import dayjs from '../../../../../shared/system/day';

const getHeaders = (translate) => {
    return [translate('status'), translate('action-log.date')];
};

const NotificationStatus = ({ statuses }) => {
    const { translate } = useContext(TranslateContext);
    const latestStatus = statuses[statuses.length - 1];

    const TooltipContent = () => {
        return (
            <Table
                headers={getHeaders(translate)}
                rows={statuses.map(({ status, createdAt }) => [
                    <strong>{status}</strong>,
                    parseDate(createdAt, 'DD.MM.YYYY, HH:mm') + getUtcOffsetText(dayjs()),
                ])}
            />
        );
    };

    return (
        <Tooltip position="left" size="content-fit" content={<TooltipContent />}>
            <Button display="stacked" appearance="link">
                <Icon InlineIcon={IconInfo} />
                {latestStatus.status}
            </Button>
        </Tooltip>
    );
};

export default NotificationStatus;
