import React, { useEffect, useState } from 'react';
import { Autocomplete, AutocompleteOptions, AutocompleteInput } from '@flixbus/honeycomb-react';

const CustomAutocomplete = ({
    id,
    iconLeft,
    optionsData,
    placeholder = 'Type to search...',
    label = 'Options',
    optionsToDisplay = 10,
    optionHasSubtitle = true,
    onSelect,
    valid,
    value: providedValue,
}) => {
    const [data, setData] = useState([]);
    const [value, setValue] = useState(providedValue);
    const [inputValue, setInputValue] = useState(providedValue);
    const [highlight, setHighlight] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        onSelect(value);
    }, [value]);

    const filterOptions = (query) => {
        const filteredData = optionsData.filter((item) =>
            item.title.toLowerCase().includes(query.toLowerCase())
        );
        setData(filteredData);
        setLoading(false);
    };

    return (
        <Autocomplete
            onChange={(e) => {
                setLoading(true);
                if (!e.target.value) {
                    setValue('');
                }
            }}
            onDebounce={(e) => {
                const query = e.target.value;
                setLoading(true);
                setHighlight(query);
                filterOptions(query);
            }}
            onSelect={(item) => {
                setData([]);
                setHighlight('');
                setValue(item.title);
            }}
            options={data}
            value={value}
        >
            <AutocompleteInput
                id={id}
                placeholder={placeholder}
                label={label}
                loading={loading}
                type="search"
                iconLeft={iconLeft}
                valid={valid}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
            />
            <AutocompleteOptions
                label={label}
                optionsToDisplay={optionsToDisplay}
                optionHasSubtitle={optionHasSubtitle}
                highlightQuery={highlight}
            />
        </Autocomplete>
    );
};

export default CustomAutocomplete;
