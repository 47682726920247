import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Accordion, Button, Heading, Legend, Fieldset } from '@flixbus/honeycomb-react';
import useGetNotificationFilters from '../../../hooks/useGetNotificationFilters';
import { TranslateContext } from '../../../../../shared/system/translate';
import { AutocompleteInput } from '../../../../../shared/components';

const NotificationFilters = ({ notifications, onFiltersUpdate }) => {
    const { translate } = useContext(TranslateContext);
    const { filters, orderIds } = useGetNotificationFilters(notifications);
    const [activeFilters, setActiveFilters] = useState({
        automatic: new Set(),
        manual: new Set(),
        channel: new Set(),
        orderId: new Set(),
    });

    useEffect(() => {
        onFiltersUpdate(activeFilters);
    }, [activeFilters]);

    const toggleFilter = (category, type) => {
        const newSet = new Set(activeFilters[category]);
        if (newSet.has(type)) {
            newSet.delete(type);
        } else {
            newSet.add(type);
        }
        setActiveFilters({ ...activeFilters, [category]: newSet });
    };

    const toggleAllFilters = (category) => {
        let newSet = new Set();
        if (activeFilters[category].size !== Object.keys(filters[category]).length) {
            Object.keys(filters[category]).forEach((type) => {
                newSet.add(type);
            });
        }
        setActiveFilters({ ...activeFilters, [category]: newSet });
    };

    const handleOrderIdSelect = (orderId) => {
        if (!orderId) {
            setActiveFilters({ ...activeFilters, orderId: new Set() });
            return;
        }
        setActiveFilters({ ...activeFilters, orderId: new Set([orderId]) });
    };

    const resetFilters = () => {
        setActiveFilters({
            automatic: new Set(),
            manual: new Set(),
            channel: new Set(),
            orderId: new Set(),
        });
    };

    return (
        <>
            <Heading size={3} sectionHeader>
                {translate('logs-filters.heading')}
            </Heading>
            {Object.keys(filters).map(
                (category) =>
                    Object.keys(filters[category]).length > 0 && (
                        <Accordion
                            key={category}
                            open={false}
                            title={
                                <Legend style={{ fontWeight: 'bold' }}>
                                    <Checkbox
                                        value=""
                                        id={`${category}_all`}
                                        label={`${translate(category)} (${
                                            Object.keys(filters[category]).length
                                        })`}
                                        checked={
                                            activeFilters[category].size ===
                                            Object.keys(filters[category]).length
                                        }
                                        onChange={() => toggleAllFilters(category)}
                                        small
                                    />
                                </Legend>
                            }
                        >
                            <Fieldset>
                                {Object.entries(filters[category]).map(([type, count]) => (
                                    <Checkbox
                                        value=""
                                        key={type}
                                        id={`${category}_${type}`}
                                        label={
                                            <span>
                                                {type} <strong>({count})</strong>
                                            </span>
                                        }
                                        checked={activeFilters[category].has(type)}
                                        onChange={() => toggleFilter(category, type)}
                                        small
                                    />
                                ))}
                            </Fieldset>
                        </Accordion>
                    )
            )}
            <Accordion title={translate('orderId')} open={true}>
                <AutocompleteInput
                    id="order_id_filter"
                    label={translate('filter_by_order_id')}
                    optionsData={orderIds?.map((orderId) => ({ title: orderId }))}
                    onSelect={handleOrderIdSelect}
                />
            </Accordion>
            <Button onClick={resetFilters} appearance="secondary" style={{ marginTop: '20px' }}>
                Clear Filters
            </Button>
        </>
    );
};

export default NotificationFilters;
