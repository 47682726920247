import React, { useContext, useState, useEffect } from 'react';
import {
    Button,
    DataTable,
    DataTableHead,
    DataTableSorter,
    Grid,
    GridCol,
} from '@flixbus/honeycomb-react';
import NotificationsLogItem from './NotificationsLogItem';
import { TranslateContext } from '../../../shared/system/translate';
import Filters from './components/Filters/Filters';
import { Icon, IconArrowBigLeft, IconArrowBigRight } from '@flixbus/honeycomb-icons-react';

const NotificationsTable = ({ notifications }) => {
    const { translate } = useContext(TranslateContext);
    const [filteredNotifications, setFilteredNotifications] = useState(notifications);
    const [sortedNotifications, setSortedNotifications] = useState([]);
    const [currentSort, setCurrentSort] = useState('descending');
    const [currentFilters, setCurrentFilters] = useState({});
    const [isNotificationWithStatus, setIsNotificationWithStatus] = useState(false);
    const [showFilters, setShowFilters] = useState(true);

    const toggleFilters = () => {
        setShowFilters((prev) => !prev);
    };

    useEffect(() => {
        filterNotifications(currentFilters);
    }, [notifications, currentFilters]);

    useEffect(() => {
        sortNotificationsByDate(currentSort);
    }, [filteredNotifications, currentSort]);

    useEffect(() => {
        setIsNotificationWithStatus(
            notifications.some((notification) => notification.status.length)
        );
    }, [notifications]);

    const sortNotificationsByDate = (sorting) => {
        setCurrentSort(sorting);

        const sorted = [...filteredNotifications].sort((a, b) => {
            if (sorting === 'ascending') {
                return a.sentAt.localeCompare(b.sentAt);
            } else if (sorting === 'descending') {
                return b.sentAt.localeCompare(a.sentAt);
            }
            return 0;
        });

        setSortedNotifications(sorted);
    };

    const sortNotificationsByAlphabet = (sorting) => {
        setCurrentSort(sorting);

        const sorted = [...filteredNotifications].sort((a, b) => {
            const textA = a.message.toLowerCase();
            const textB = b.message.toLowerCase();

            if (sorting === 'ascending') {
                return textA.localeCompare(textB);
            } else if (sorting === 'descending') {
                return textB.localeCompare(textA);
            }
            return 0;
        });

        setSortedNotifications(sorted);
    };

    const filterNotifications = (filters) => {
        const filtered = notifications.filter((notification) => {
            return Object.entries(filters).every(([key, value]) => {
                if (value.size > 0) {
                    return Array.from(value).some(
                        (filter) => filter.toLowerCase() === notification[key]?.toLowerCase()
                    );
                }
                return true;
            });
        });

        setCurrentFilters(filters);
        setFilteredNotifications(filtered);
    };

    const headers = (
        <DataTableHead>
            <DataTableSorter
                aria-label="Sort logs by date"
                onSortingChange={sortNotificationsByDate}
            >
                {translate('action-log.date')}
            </DataTableSorter>
            <DataTableSorter
                aria-label="Sort logs by order id"
                onSortingChange={sortNotificationsByAlphabet}
            >
                {translate('order_id_recipient')}
            </DataTableSorter>
            <th>{translate('channel')}</th>
            <th>{translate('message')}</th>
            {isNotificationWithStatus ? <th>{translate('status')}</th> : null}

            <th>{translate('sentBy')}</th>
        </DataTableHead>
    );

    return (
        <>
            <Grid>
                {showFilters && (
                    <GridCol size={2}>
                        <Filters
                            notifications={notifications}
                            onFiltersUpdate={filterNotifications}
                        />
                    </GridCol>
                )}

                <GridCol size={showFilters ? 10 : 12}>
                    <div style={{ display: 'flex', width: '100%', alignItems: 'flex-start' }}>
                        <Button display="square" appearance="link" onClick={toggleFilters}>
                            <Icon InlineIcon={showFilters ? IconArrowBigLeft : IconArrowBigRight} />
                        </Button>
                        <div style={{ flex: 1 }}>
                            <DataTable small headers={headers} style={{ width: '100%' }}>
                                {sortedNotifications.map((notification) => (
                                    <NotificationsLogItem
                                        key={notification.id}
                                        notification={notification}
                                        isNotificationWithStatus={isNotificationWithStatus}
                                    />
                                ))}
                            </DataTable>
                        </div>
                    </div>
                </GridCol>
            </Grid>
        </>
    );
};

export default NotificationsTable;
