import React, { useContext, useState } from 'react';
import { Button, DataTableRow, Tooltip } from '@flixbus/honeycomb-react';
import { Icon, IconNewsletterSolid, IconPhoneTablet } from '@flixbus/honeycomb-icons-react';
import { getUtcOffsetText, parseDate } from '../../../shared/util/date';
import dayjs from '../../../shared/system/day';
import { TranslateContext } from '../../../shared/system/translate';
import { NotificationStatus } from './components/NotificationStatus';

const NotificationsLogItem = ({ notification, isNotificationWithStatus }) => {
    const [formatted, setFormatted] = useState(false);
    const { translate } = useContext(TranslateContext);

    const hasHTML = () => {
        return notification.message.search(/<\/?\w*\/?>/gi) !== -1;
    };

    const toggleFormatted = () => {
        setFormatted(!formatted);
    };

    const sanitizeMessage = () => {
        const test = notification.message;
        return test.replace(/(<script.*?>.*<\/script>)|(<style.*?>.*<\/style>)/gi, '');
    };

    const sortStatusesByDate = (statuses) => {
        return [...statuses].sort((a, b) => {
            return dayjs(a.createdAt).diff(dayjs(b.createdAt));
        });
    };

    return (
        <DataTableRow>
            <td style={{ minWidth: '180px' }}>
                {parseDate(notification.sentAt, 'DD.MM.YYYY') + getUtcOffsetText(dayjs())}
                <br />
                {parseDate(notification.sentAt, 'HH:mm:ss')}
            </td>
            <td>
                <strong>{notification.orderId}</strong>
                <br />
                <span style={{ fontSize: '14px' }}>{notification.recipient}</span>
            </td>
            <td style={{ paddingRight: '20px' }}>{notification.channel}</td>
            <td>
                {hasHTML() && (
                    <Tooltip
                        content={translate('notifications-log.format-email')}
                        id="format-email"
                        smartPosition
                        size="content-fit"
                        openOnHover
                    >
                        <Button appearance="link" onClick={toggleFormatted}>
                            <Icon appearance="primary" InlineIcon={IconNewsletterSolid} />
                        </Button>
                    </Tooltip>
                )}
                {formatted ? (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: sanitizeMessage(),
                        }}
                    />
                ) : (
                    notification.message.replace(/<\/?\w*\/?>|&nbsp;/gi, ' ').replace(/\s+/, ' ')
                )}
            </td>
            {notification.isPush && (
                <td>
                    <Tooltip
                        id="push-id"
                        content="Push"
                        openOnHover
                        smartPosition
                        size="content-fit"
                    >
                        <Icon appearance="primary" InlineIcon={IconPhoneTablet} title="Push" />
                    </Tooltip>
                </td>
            )}
            {isNotificationWithStatus ? (
                <td>
                    {notification.status.length ? (
                        <NotificationStatus statuses={sortStatusesByDate(notification.status)} />
                    ) : null}
                </td>
            ) : null}
            <td style={{ maxWidth: '300px' }}>{notification.sentBy}</td>
        </DataTableRow>
    );
};

export default NotificationsLogItem;
